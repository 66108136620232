<template>
    <b-overlay :show="showOverlay" variant="secondary" static>

        <div class="card-header">
            <div class="w-100 d-flex justify-content-start">
                <!-- <h4>My Policiies</h4> -->
                <!-- <p></p> -->
                <b-input-group>
                <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                class="w-75"
                v-model="search"
                type="search"
                placeholder="Search Policies..."
                ></b-form-input>
            </b-input-group>
            </div>
            
        </div>
        <div class="card-body">
            <table role="table" class="table b-table">
              <thead role="rowgroup">
                <tr role="row">
                  <th role="columnheader" scope="col">Policy Name</th>
                  <th role="columnheader" scope="col">Description</th>
                  <th role="columnheader" scope="col">Status</th>
                  <!-- <th role="columnheader" scope="col" class="text-center">
                    Version
                  </th> -->
                  <th role="columnheader" scope="col" class="text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody role="rowgroup" v-if="policies.length">
                <template
                  
                >
                  <template v-for="policy in policies">
                    <tr :key="policy.request_id" role="row" class="cursor-pointer" @click="handleRowClick(policy)">
                        <td aria-colindex="1" role="cell" style="max-width: 20vw">
                        <div
                          class="d-flex flex-column align-items-start justify-content-center w-100"
                        >
                          <p class="mb-0">
                           {{ policy.policygroup_name}}
                          </p>

                          <!-- <small class="mt-25"
                            >Version:&nbsp;
                            <b-badge variant="light-primary">{{
                              standard.version
                            }}</b-badge></small
                          > -->
                        </div>
                      </td>

                      <td aria-colindex="1" role="cell" style="max-width: 20vw">
                        
                          {{ policy.description  }}
                       
                      </td>


                      <td aria-colindex="2" role="cell">
                        <b-badge :variant="policy.status===1?'info':policy.status===2?'warning':policy.status===3?'success':'danger'">{{ policy.status===1?'Queued':policy.status===2?'Processing':policy.status===3?'Success':'Failed' }}</b-badge>
                      </td>

                      <td aria-colindex="2" role="cell">
                        <div
                          class="d-flex flex-column align-items-center justify-content-center"
                        >
                        <b-button 
                        v-if="policy.status===1||policy.status===2"
                        size="sm"
                        variant="primary"
                        @click.stop="load"
                        >Refresh</b-button>
                        <div v-if="policy.status===3">
                          <b-button
                          v-if="loading.includes(policy.request_id)"
                              
                              size="sm"
                              variant="success"
                              disabled
                              ><b-spinner small variant="secondary"/>Downloading</b-button
                            >
                            <b-button 
                            v-else
                              @click.stop="handleDownload(policy.request_id,policy.policygroup_name)"
                              size="sm"
                              variant="success"
                              ><feather-icon
                                icon="DownloadIcon"
                                class="mr-50"
                              />Download</b-button
                            >
                        </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                </template>
                <!-- <template
                  v-if="nonImportedStandards && nonImportedStandards.length > 0"
                >
                  <template v-for="standard in nonImportedStandards">
                    <tr :key="standard._id" role="row" class="cursor-pointer">
                        <td aria-colindex="1" role="cell" style="max-width: 20vw">
                        <div
                          class="d-flex flex-column align-items-start justify-content-center w-100"
                        >
                          <p class="mb-0 font-weight-bold">
                            {{ standard.name }}
                          </p>

                          <small class="mt-25"
                            >Version:&nbsp;
                            <b-badge variant="light-primary">{{
                              standard.version
                            }}</b-badge></small
                          >
                        </div>
                      </td>

                      <td aria-colindex="1" role="cell" style="max-width: 20vw">
                        <p
                          class="mb-0 font-weight-bold text-truncate"
                          v-b-tooltip.hover.top.v-primary
                          :title="standard.description"
                        >
                          {{ standard.description }}
                        </p>
                      </td>

                      <td style="max-width: 15vw" role="cell">
                        <div
                          class="d-flex flex-wrap align-items-center justify-content-start w-100"
                        >
                          <template
                            v-if="
                              standard.industries && standard.industries != null
                            "
                          >
                            <template v-for="(stnd, i) in standard.industries">
                              <b-badge
                                variant="primary"
                                :key="i"
                                class="mr-50 my-25"
                                >{{ stnd.name }}</b-badge
                              >
                            </template>
                          </template>
                          <template v-else>
                            <span></span>
                          </template>
                        </div>
                      </td>

                  

                      <td aria-colindex="2" role="cell">
                        <div
                          class="d-flex flex-column align-items-center justify-content-center"
                        >
                          <b-button
                            @click="handleImportStandardClick(standard._id)"
                            size="sm"
                            variant="success"
                            ><feather-icon
                              icon="DownloadIcon"
                              class="mr-50"
                            />Import</b-button
                          >
                        </div>
                      </td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <empty-table-section title="Standards Not Found">
                    <template #content>
                      <p class="font-weight-bold text-center">
                        It seems like there are no Standards at the moment.
                      </p>
                    </template>
                  </empty-table-section>
                </template> -->
              </tbody>
              <template v-else>
                  <empty-table-section title="Policies Not Found">
                    <template #content>
                      <p class="font-weight-bold text-center">
                        It seems like there are no policies at the moment.
                      </p>
                    </template>
                  </empty-table-section>
                </template>
            </table>
        </div>
    </b-overlay>
</template>
<script>
import PoliciesMixins from "@/mixins/PoliciesMixins";
import { BBadge, BButton, BOverlay, BSpinner,BInputGroup,
        BInputGroupPrepend,
        BFormInput, } from "bootstrap-vue"
import EmptyTableSection from "@/components/EmptyTableSection.vue";
import {mapActions} from 'vuex'


export default{
    components:{
        // BTable
        BBadge,
        BButton,
        BOverlay,
        BSpinner,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        EmptyTableSection
    },
    data(){
      return {
        policies:[],
        loading:[],
        search:'',
        showOverlay:false,
        
      }
    },
    mixins:[PoliciesMixins],
    mounted(){
      this.load()
    },
    methods:{
      ...mapActions('policies',['setMyPolicy']),
      load(){
        this.getPolicies()
      },
      async getPolicies(){
       try {
        this.showOverlay=true
         const res=await this.getMypolicies({name:this.search})
         this.policies=res.data
         this.showOverlay=false
       } catch (error) {
        this.showOverlay=false
       }


      },
      handleRowClick(policy){
        this.setMyPolicy(policy)
          this.$router.push(`/policies/my/${policy.request_id}/details`)
        },
        async handleDownload(reqId,name){
          const options = {
              method: "GET",
              headers: { "content-type": "application/x-www-form-urlencoded" },
              url: `${process.env.VUE_APP_BASEURL}/toolkit/download-policies`,
              params:{request_id:reqId},
              responseType: 'blob'
            };
            
            try {
              this.loading.push(reqId)
              const res = await this.$http(options);
              // Create a Blob from the response data
              const blob = new Blob([res.data], { type: 'application/zip' });
              // Create a link element
              const link = document.createElement('a');
              // Create a URL for the Blob
              const url = window.URL.createObjectURL(blob);
              // Set the link's href to the Blob URL
              link.href = url;
              // Set the download attribute with a filename
              link.download = `download_${name}.zip`; // Customize the file name as needed
              // Append the link to the document body
              document.body.appendChild(link);
              // Trigger a click event on the link
              link.click();
              // Clean up and remove the link
              document.body.removeChild(link);
              window.URL.revokeObjectURL(url);
            } catch (error) {
              console.error('Error downloading the file:', error);
              // Handle the error as needed
            }finally {
              const index = this.loading.indexOf(reqId);
              if (index !== -1) {
                this.loading.splice(index, 1);
              }
            }
         
        }
    },
    watch:{
      search(){
        this.getPolicies()
      }
    }
}
</script>
