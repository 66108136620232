<template>
    <b-overlay :show="showOverlay" variant="secondary">
        <div>
            <b-sidebar
            id="sidebar-backdrop"
            bg-variant="white"
            backdrop
            right
            no-header
            shadow
            lazy
            width="580px"
            sidebar-class="sidebar-md"
            >
            <sidebar-template
                title="Generate New Policy"
                :closeAction="closeSidebar"
            >
                <template #content>
                    <generate-policy-form :cancelAction="closeSidebar" :variableDetails="variables" :policy="selectedPolicy"/>
                </template>
            </sidebar-template>
            </b-sidebar>
           <div class="card-header">
            <b-input-group>
                <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                class="w-75"
                v-model="search"
                type="search"
                placeholder="Search Policies..."
                ></b-form-input>
            </b-input-group>
           </div>
           <div class="card-body">
            <table role="table" class="table b-table" >
              <thead role="rowgroup">
                <tr role="row">
                  <th role="columnheader" scope="col">Policy Name</th>
                  <th role="columnheader" scope="col">Description</th>
                  <!-- <th role="columnheader" scope="col">Status</th> -->
                  <!-- <th role="columnheader" scope="col" class="text-center">
                    Version
                  </th> -->
                  <th role="columnheader" scope="col" class="text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody role="rowgroup" v-if="policies.length">
                <template
                  
                >
                  <template v-for="policy in policies">
                    <tr :key="policy.group_id" role="row" class="cursor-pointer" @click="handleRowClick(policy)">
                        <td aria-colindex="1" role="cell" style="max-width: 20vw">
                        <div
                          class="d-flex flex-column align-items-start justify-content-center w-100"
                        >
                        <p class="mb-0 ">
                           {{ policy.group_name}}
                          </p>
                          <p class="mb-0 ">
                           <!-- {{ policy.group_description }} -->
                          </p>

                          <!-- <small class="mt-25"
                            >Version:&nbsp;
                            <b-badge variant="light-primary">{{
                              standard.version
                            }}</b-badge></small
                          > -->
                        </div>
                      </td>

                      <td aria-colindex="1" role="cell" style="max-width: 20vw">
                        
                          {{ policy.group_description }}
                       
                      </td>


                      <!-- <td aria-colindex="2" role="cell">
                        <b-badge variant="warning">processing</b-badge>
                      </td> -->

                      <td aria-colindex="2" role="cell">
                        <div
                          class="d-flex flex-column align-items-center justify-content-center"
                        >
                          <b-button
                            @click.stop="handleGeneratePolicy(policy)"
                            size="sm"
                            variant="primary"
                            ><feather-icon
                              icon="PlusCircleIcon"
                              class="mr-50"
                            />Generate Policy</b-button
                          >
                        </div>
                      </td>
                    </tr>
                  </template>
                </template>
                <!-- <template
                  v-if="nonImportedStandards && nonImportedStandards.length > 0"
                >
                  <template v-for="standard in nonImportedStandards">
                    <tr :key="standard._id" role="row" class="cursor-pointer">
                        <td aria-colindex="1" role="cell" style="max-width: 20vw">
                        <div
                          class="d-flex flex-column align-items-start justify-content-center w-100"
                        >
                          <p class="mb-0 font-weight-bold">
                            {{ standard.name }}
                          </p>

                          <small class="mt-25"
                            >Version:&nbsp;
                            <b-badge variant="light-primary">{{
                              standard.version
                            }}</b-badge></small
                          >
                        </div>
                      </td>

                      <td aria-colindex="1" role="cell" style="max-width: 20vw">
                        <p
                          class="mb-0 font-weight-bold text-truncate"
                          v-b-tooltip.hover.top.v-primary
                          :title="standard.description"
                        >
                          {{ standard.description }}
                        </p>
                      </td>

                      <td style="max-width: 15vw" role="cell">
                        <div
                          class="d-flex flex-wrap align-items-center justify-content-start w-100"
                        >
                          <template
                            v-if="
                              standard.industries && standard.industries != null
                            "
                          >
                            <template v-for="(stnd, i) in standard.industries">
                              <b-badge
                                variant="primary"
                                :key="i"
                                class="mr-50 my-25"
                                >{{ stnd.name }}</b-badge
                              >
                            </template>
                          </template>
                          <template v-else>
                            <span></span>
                          </template>
                        </div>
                      </td>

                  

                      <td aria-colindex="2" role="cell">
                        <div
                          class="d-flex flex-column align-items-center justify-content-center"
                        >
                          <b-button
                            @click="handleImportStandardClick(standard._id)"
                            size="sm"
                            variant="success"
                            ><feather-icon
                              icon="DownloadIcon"
                              class="mr-50"
                            />Import</b-button
                          >
                        </div>
                      </td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <empty-table-section title="Standards Not Found">
                    <template #content>
                      <p class="font-weight-bold text-center">
                        It seems like there are no Standards at the moment.
                      </p>
                    </template>
                  </empty-table-section>
                </template> -->
              </tbody>
              <template v-else>
                  <empty-table-section title="Policies Not Found">
                    <template #content>
                      <p class="font-weight-bold text-center">
                        It seems like there are no policies at the moment.
                      </p>
                    </template>
                  </empty-table-section>
                </template>
            </table>
            
           </div>
        </div>
    </b-overlay>
</template>
<script>
import { 
    BButton,
     BFormInput,
    BInputGroup, 
    BInputGroupPrepend, 
    BOverlay, 
    BSidebar
} from 'bootstrap-vue';
import GeneratePolicyForm from '@/views/Policies/components/GeneratePolicyForm.vue'
import SidebarTemplate from "@/components/SidebarTemplate.vue";
import PoliciesMixins from '@/mixins/PoliciesMixins';
import ResponseMixins from '@/mixins/ResponseMixins';
import EmptyTableSection from "@/components/EmptyTableSection.vue";
import {mapActions} from 'vuex'

export default {
    data(){
      return {
        policies:[],
        variables:[],
        selectedPolicy:{},
        search:'',
        showOverlay:false
      }
    },
    components:{
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BOverlay,
        BButton,
        BSidebar,
        GeneratePolicyForm,
        SidebarTemplate,
        EmptyTableSection
    },
    mixins:[PoliciesMixins,ResponseMixins],
    mounted() {
    this.load();
  },
    methods:{
      ...mapActions('policies',['setPolicy']),
      load(){
        this.setPolicyGroups()
      },
      async setPolicyGroups(){
        try {
          this.showOverlay=true
          const res=await this.getPolicyGroups({name:this.search})
          this.policies=res.data
          this.showOverlay=false
        } catch (error) {
          this.showOverlay=false
          this.handleError(error)
        }
      },
        handleGeneratePolicy(policy){
            this.variables=policy?.variables?.map(item=>item.variable_detail)
            this.selectedPolicy=policy
            this.setPolicy(policy)
            this.$router.push(`policies/all/${policy.group_id}/add`)
            
            // this.openSidebar()
        },
        handleRowClick(policy){
          this.setPolicy(policy)
          this.$router.push(`/policies/all/${policy.group_id}/details`)
        },
        closeSidebar: function () {
        this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
        },
        openSidebar: function () {
        this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
        },
    },
    watch:{
      search(){
        this.load()
      }
    }
}
</script>