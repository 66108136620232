<template>
    
  <b-tabs lazy content-class="mt-3 card h-100">
    <b-tab title="My Policies" active>
        <my-policies/>

    </b-tab>
    <b-tab title="All Policies">
        <all-policies/>
    </b-tab>

  </b-tabs>
</template>
<script>
import 
    { BTabs,
        BTab,
        BOverlay,
        
        
    } 
 from 'bootstrap-vue';
 import AllPolicies from "./components/AllPolicies.vue"
 import MyPolicies from "./components/MyPolicies.vue"

export default {
    components:{
        BTabs,
        BTab,
        BOverlay,
        AllPolicies,
        MyPolicies

    }
}
</script>
